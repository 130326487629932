:root {
	--primary-color: #313639;
	--secondary-color: #ffffff;
	--primary-font: 'Cormorant Garamond', serif;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	overflow-x: hidden;
}

html {
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
	color: inherit;
}

.mt-8 {
	margin-top: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.fw-700 {
	font-weight: 700;
}

.fs-2 {
	font-size: 2em;
}

.fs-4 {
	font-size: 4em;
}

.center {
	text-align: center;
}

body {
	background-color: var(--secondary-color);
	color: var(--primary-color);
	font-family: var(--primary-font);
}

.flex {
	display: flex;
	flex-direction: column;
}
.flex-reverse-mobile {
	display: flex;
	flex-direction: column-reverse;
}

.row {
	display: flex;
	flex-direction: row;
}

.flex-1 {
	flex: 1;
	width: 100vw;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.boxed {
	padding: 3% 6%;
}

.elevated-div {
	border-radius: 16px;
	box-shadow: 0 0 15px rgb(0, 0, 0, 0.5);
	background-color: var(--secondary-color);
	/* width: 100%; */
}

.full-width {
	width: 100vw;
}

.form-input {
	background: #f0f0f0;
	border: 1px solid var(--primary-color);
	border-radius: 6px;
	padding: 16px;
	width: 100%;
}

.form-btn {
	width: 100%;
	border: none;
	border-radius: 8px;
	padding: 16px;
	background-color: #0275d8;
	color: var(--secondary-color);
	cursor: pointer;
}
.form-btn-red {
	width: 100%;
	border: none;
	border-radius: 8px;
	padding: 16px;
	background-color: red;
	color: var(--secondary-color);
	cursor: pointer;
}

.primary-btn {
	border: none;
	border-radius: 8px;
	padding: 16px 32px;
	background-color: #0275d8;
	color: var(--secondary-color);
	cursor: pointer;
}

.primary-btn-red {
	border: none;
	border-radius: 8px;
	padding: 16px 32px;
	background-color: red;
	color: var(--secondary-color);
	cursor: pointer;
}

.alt-btn {
	border: 1px solid #0275d8;
	border-radius: 8px;
	padding: 16px 32px;
	background-color: transparent;
	color: var(--secondary-color);
	cursor: pointer;
}
.alt-btn-black {
	border: 1px solid #0275d8;
	border-radius: 8px;
	padding: 16px 32px;
	background-color: transparent;
	color: var(--primary-color);
	cursor: pointer;
}



.feed-format {
	background-color: var(--secondary-color);
	margin-top: 16px;
}

.feed-format-title {
	font-size: 16px;
	font-weight: 700;
}

.repo {
	background: #121212;
	color: var(--secondary-color);
}

.services {
	overflow: hidden;
}

.service-img-div {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 20%;
}

.service-title-div {
	background-color: #121212;
	color: var(--secondary-color);
	font-size: 1.5em;
	font-weight: 700;
}

.quote {
	background-color: #121212;
}

.quote-intro {
	color: var(--secondary-color);
	font-weight: 700;
	font-size: 1.5em;
	margin: auto;
}

.contact-brand-title {
	color: #c0c0c0;
	font-size: 1.2em;
	font-weight: 700;
}

.contact-title {
	font-weight: 700;
	font-size: 1.5em;
}
.services .elevated-div {
	background-color: #f0f0f0;
	padding-top: 15%;
	padding-bottom: 15%;
}
.team-container {
	background-color: rgb(230, 216, 215);
}
.dashboard {
	display: flex;
	height: 100vh;
}
.mobile-only {
	display: block;
}
.dashboard-sidenav-div {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #f0f0f0;
	z-index: 1;
	overflow-x: hidden;
	transition: 0.5s;
}
.dashboard-page {
	width: 100%;
	overflow-y: auto;
}
.error {
	background-color: red;
	color: var(--secondary-color);
	padding: 16px 32px;
	width: 100%;
}
.success {
	color: green;
}

.fa-file {
	font-size: 1.5em;
	padding-right: 5%;
}
table {
	width: 100%;
}
td {
	text-align: center;
	padding: 16px;
}
tr:nth-child(odd) {
	background-color: #f0f0f0;
}
thead tr:nth-child(odd) {
	background: none;
}

@keyframes spin {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}
.spin {
	animation-name: spin;
	animation-iteration-count: infinite;
	animation-timing-function: infinite;
	animation-duration: 1s;
}

@media only screen and (min-width: 992px) {
	.flex, .flex-reverse-mobile {
		display: flex;
		flex-direction: row;
	}

	.flex-1 {
		width: auto;
	}

	.full-page {
		/* width: 100vw; */
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		overflow: hidden;
	}

	.home {
		background-image: url("./assets/images/orisha3.png");
	}

	.blog {
		background-image: url("./assets/images/typing.jpeg");
	}

	.videos {
		background-image: url("./assets/images/orisha2.png");
	}

	.blog .elevated-div {
		height: 500px;
		overflow: auto;
	}

	.blog .elevated-div::-webkit-scrollbar {
		display: none;
	}

	.nav {
		position: fixed;
		top: 0;
		background: transparent;
		left: 0;
		width: 100%;
		/* background: rgb(0, 0, 0, 0.1); */
		color: var(--secondary-color);
	}
	.service-display {
		width: 75%;
		margin: auto;
	}

	.quote-intro {
		font-size: 4em;
		width: 75%;
	}

	.contact-brand-title {
		font-size: 2em;
	}

	.contact-title {
		font-size: 2em;
	}
	.padded-div {
		margin: auto;
		width: 60%;
	}

	.about-page, .register-page, .service-page {
		padding-top: 15%;
	}
	.dashboard {
		display: flex;
		flex-direction: row;
	}
	.dashboard-sidenav-div {
		width: 15%;
		background-color: #f0f0f0;
		position: relative;
	}
	.dashboard-body-div {
		display: block;
		width: 85%;
	}
	.mobile-only {
		display: none;
	}
}